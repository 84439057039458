import { useConsumerTripInfo } from "hooks/useConsumerTripInfo";
import { useCallback } from "react";

import { useConsumerTripEventCreateMutation } from "../gql";

export type ConsumerTripEventInput = {
    type: string;
    meta: Record<string, unknown>;
};

export function useConsumerTripEventRegistration() {
    const [mutate] = useConsumerTripEventCreateMutation();
    const { consumerTripId } = useConsumerTripInfo();
    return useCallback(
        (input: ConsumerTripEventInput) => {
            return mutate({
                variables: {
                    consumerTripId,
                    ...input,
                },
            });
        },
        [mutate, consumerTripId]
    );
}
