import { ConsumerTripEventWLQuesitonnaireInterestUnselectedCreateInput } from "@holibob-packages/consumer-trip-event";

export class QuestionnaireInterestUnselectEvent extends Event {
    static NAME = "questionnaire:interest-unselect";

    constructor(readonly details: ConsumerTripEventWLQuesitonnaireInterestUnselectedCreateInput["meta"]) {
        super(QuestionnaireInterestUnselectEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }

    clone() {
        return new QuestionnaireInterestUnselectEvent(this.details);
    }
}
