import { ConsumerTripEventWLQuesitonnaireNextClickedCreateInput } from "@holibob-packages/consumer-trip-event";

export class QuestionnaireNextClickEvent extends Event {
    static NAME = "questionnaire:next-click";

    constructor(readonly details: ConsumerTripEventWLQuesitonnaireNextClickedCreateInput["meta"]) {
        super(QuestionnaireNextClickEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }

    clone() {
        return new QuestionnaireNextClickEvent(this.details);
    }
}
