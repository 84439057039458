import { ConsumerTripContentItemProductListType } from "@holibob-packages/graphql-types";

import { ProductItem } from "../types";

export class StoryDisplayEvent extends Event {
    static NAME = "story:display";

    constructor(
        readonly details: {
            itemList: ProductItem[];
            shelfType: ConsumerTripContentItemProductListType;
        }
    ) {
        super(StoryDisplayEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }
}
