import { Skeleton } from "@mui/material";
import { Breadcrumbs } from "@mui/material";
import { useSearchParams } from "next/navigation";

import { Link } from "@holibob-packages/ui-core/navigation";
import { urlCuration, UrlQueryParams } from "@holibob-packages/url";

import { useBreadcrumbListQuery, CurationBreadcrumbFragment } from "../../gql";
import { useIsCurationBreadcrumbsEnabled } from "../../hooks/useIsCurationBreadcrumbsEnabled";

export const parsePathToCurationBreadcrumbItems = (path?: string | null, currentCurationId?: string) => {
    const items = (path ?? "").split(",").filter((x) => !!x);

    return currentCurationId ? [...items, currentCurationId] : items;
};

export const useBreadcrumbItems = (currentCurationId?: string) => {
    const searchParams = useSearchParams();
    const path = searchParams.get(UrlQueryParams.curationPath);
    const items = parsePathToCurationBreadcrumbItems(path, currentCurationId);

    return { items };
};

export interface CurationBreadcrumbsContentProps {
    breadcrumbs: CurationBreadcrumbFragment[];
    path: string;
}

export function CurationBreadcrumbsContent({ breadcrumbs, path }: CurationBreadcrumbsContentProps) {
    const content = [
        <Link href="/" key="static-home-link">
            Home
        </Link>,
    ].concat(
        breadcrumbs.map(({ id, name, slug }, index) => {
            const curationIdsPath = path.split(",").slice(0, index);

            return (
                <Link key={id} href={urlCuration({ curationId: id, slug, curationIdsPath })} color="textPrimary">
                    {name}
                </Link>
            );
        })
    );

    return <Breadcrumbs>{content}</Breadcrumbs>;
}

type CurationBreadcrumbsProps = { currentCurationId?: string };

export const CurationBreadcrumbs = ({ currentCurationId }: CurationBreadcrumbsProps) => {
    const { items } = useBreadcrumbItems(currentCurationId);
    const isEnabled = useIsCurationBreadcrumbsEnabled();

    const { data, loading } = useBreadcrumbListQuery({
        variables: { items },
        skip: !isEnabled,
    });

    if (!isEnabled) {
        return null;
    }

    if (loading) {
        return <Skeleton height={48} width="50%" />;
    }

    const breadcrumbList = data?.breadcrumbList;

    if (!breadcrumbList) return null;

    const breadcrumbs = breadcrumbList.nodes;

    return <CurationBreadcrumbsContent breadcrumbs={breadcrumbs} path={items.join(",")} />;
};
