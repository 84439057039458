import { ConsumerTripEventWLQuesitonnaireInterestSelectedCreateInput } from "@holibob-packages/consumer-trip-event";

export class QuestionnaireInterestSelectEvent extends Event {
    static NAME = "questionnaire:interest-select";

    constructor(readonly details: ConsumerTripEventWLQuesitonnaireInterestSelectedCreateInput["meta"]) {
        super(QuestionnaireInterestSelectEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }

    clone() {
        return new QuestionnaireInterestSelectEvent(this.details);
    }
}
