import { gql } from "@apollo/client";
import useProductList from "hooks/api/useProductList";
import { useEntityConfigurationValueByKey } from "hooks/useEntityConfiguration";
import useListSearch from "hooks/useListSearch";

export const QUERY = gql`
    query useFeaturedProducts($filter: ProductListFilter, $sort: ProductListSort, $page: Int, $pageSize: Int) {
        productList(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
            total
            pages
            nextPage
            prevPage
            products: nodes {
                id
                name
                slug
                isFeatured
                promotionType
                guidePriceType
                isFavourite
                holibobGuidePrice {
                    gross
                    currency
                    pricingData
                }
                previewImage {
                    id
                }
                metaList {
                    nodes {
                        type
                        value
                    }
                }
                cancellationPolicy {
                    hasFreeCancellation
                }
                reviewCount
                reviewRating
            }
        }
    }
`;

export const queryArgs = {
    query: QUERY,
    variables: {
        filter: { isFeatured: true },
        sort: { isRecommended: "desc" },
        page: 1,
        pageSize: 36,
    },
};

export async function fetchFeaturedProducts(apolloClient: $TSFixMe, variables = {}) {
    await apolloClient.query({
        query: QUERY,
        variables: {
            filter: { isFeatured: true },
            sort: { isRecommended: "desc" },
            pageSize: 27,
            page: 1,
            ...variables,
        },
    });
}

export const useFeaturedProducts = ({ pageSize: pageSizeProp = 27, options }: $TSFixMe) => {
    const curationId = useEntityConfigurationValueByKey("SETTING.HOME_CURATION_ID");

    const initialFilter = curationId ? { curationId } : { isFeatured: true };

    const [search, onChange] = useListSearch({
        initialParams: { filter: initialFilter, pageSize: pageSizeProp },
    });

    const { filter, sort, pageSize, page } = search;

    const { error, hasRootErrors, loading, products, nextPage, prevPage, noResults, pagination } = useProductList({
        variables: { filter, sort, page: page + 1, pageSize },
        query: QUERY,
        options,
    });

    return {
        error,
        loading,
        products,
        nextPage,
        prevPage,
        noResults,
        onChange,
        pagination,
        hasRootErrors,
    };
};

export default useFeaturedProducts;
