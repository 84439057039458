"use client";

import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { HbmlContentRenderer } from "components/Hbml/HbmlContentRenderer";
import { CurationBreadcrumbs, useBreadcrumbItems } from "containers/Curation/CurationBreadcrumbs";
import { CurationGroup } from "containers/Curation/CurationGroup";
import { UseProductFilteredListCreateContextParams } from "containers/ProductFilteredList/hooks";
import { useEntityConfigurationValueByKey } from "hooks/useEntityConfiguration";
import { useProductUrlGenerator } from "hooks/useProductUrlGenerator";

import type { Hbml } from "@holibob-packages/hbml";
import {
    ErrorBoundary,
    Flex,
    FadeUpAnimationWrapper,
    HbmlComponents,
    HbmlRenderer,
    HeroImage,
    Typography,
    MarketPlaceTileA,
} from "@holibob-packages/ui-core/components";
import { CurationsContainerGrid } from "@holibob-packages/ui-core/curation";
import { urlCuration } from "@holibob-packages/url";
import { AssetUrl } from "@holibob-packages/vault";

import { LayoutContent } from "../../components/Layout";
import {
    CurationContentItemCurationFragment,
    CurationContentItemGroupFragment,
    CurationContentItemCurationParentFragment,
    CurationContentQuery,
} from "../../gql";
import { CurationProductsFilteredListV2 } from "./CurationProductsFilteredListV2";
import { CurationProductsGridV2 } from "./CurationProductsGridV2";

export type CurationProps = {
    curation: NonNullable<CurationContentQuery["curationContent"]>["curation"];
    curations: CurationContentItemCurationFragment[];
    groups: CurationContentItemGroupFragment[];
    siblings: CurationContentItemCurationFragment[];
    curationParent: CurationContentItemCurationParentFragment | null;
    showHeader?: boolean;
    showProductListTitle?: boolean;
    showProductList?: boolean;
};

export function CurationV2(props: CurationProps) {
    const { curation, curationParent, curations, groups, siblings } = props;
    const { showHeader = true, showProductListTitle = true, showProductList = true } = props;

    return (
        <>
            {showHeader && curation.heroImageAsset && (
                <HeroImage src={curation.heroImageAsset.uri} name={curation.name} />
            )}

            <Wrapper>
                {showHeader && <CurationHeader curation={curation} />}

                {showProductList && (
                    <CurationProductListContainer curation={curation} showTitle={showProductListTitle} />
                )}

                <CurationOfCurationList curation={curation} curations={curations} />

                <CurationGroups curation={curation} groups={groups} />

                <CurationSiblingList curation={curation} curationParent={curationParent} siblings={siblings} />

                {curation.endContent?.raw && <HbmlContentRenderer content={curation.endContent.raw as Hbml} />}
            </Wrapper>
        </>
    );
}

const Wrapper = styled(LayoutContent)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.airiness,
}));

type CurationHeaderProps = Pick<CurationProps, "curation">;

function CurationHeader({ curation }: CurationHeaderProps) {
    return (
        <Flex gap={10} column>
            <CurationBreadcrumbs currentCurationId={curation.id} />
            <Typography variant="display" color="primary">
                {curation.name}
            </Typography>
            {curation.description && (
                <div>
                    <HbmlRenderer
                        content={curation.description.raw as Hbml}
                        components={HbmlComponents.HbmlStandardPreset}
                    />
                </div>
            )}
        </Flex>
    );
}

type CurationProductListContainerProps = Pick<CurationProps, "curation"> &
    Pick<UseProductFilteredListCreateContextParams, "showTitle">;

function CurationProductListContainer(props: CurationProductListContainerProps) {
    const { curation, showTitle } = props;

    const { items: curationIdsPath } = useBreadcrumbItems(curation.id);
    const productUrlGenerator = useProductUrlGenerator({ curationIdsPath });
    const isCurationGridMode = useEntityConfigurationValueByKey<boolean>("SETTING.CURATION_GRID") ?? false;
    const isFadeUpAnimation = useEntityConfigurationValueByKey<boolean>("SETTING.SECTION_FADE_UP_ANIMATION") ?? false;
    return (
        <ErrorBoundary>
            <FadeUpAnimationWrapper isEnabled={isFadeUpAnimation}>
                {isCurationGridMode ? (
                    <CurationProductsGridV2 curationId={curation.hierarchyId} urlGenerator={productUrlGenerator} />
                ) : (
                    <CurationProductsFilteredListV2
                        curation={curation}
                        showTitle={!!showTitle}
                        urlGenerator={productUrlGenerator}
                    />
                )}
            </FadeUpAnimationWrapper>
        </ErrorBoundary>
    );
}

type CurationListProps = Pick<CurationProps, "curation" | "curations">;

function CurationOfCurationList({ curation, curations }: CurationListProps) {
    const isFadeUpAnimation = useEntityConfigurationValueByKey<boolean>("SETTING.SECTION_FADE_UP_ANIMATION") ?? false;
    const { items } = useBreadcrumbItems(curation.id);

    if (curations.length === 0) return null;

    return (
        <FadeUpAnimationWrapper isEnabled={isFadeUpAnimation}>
            <CurationsContainerGrid>
                {curations.map((curation) => (
                    <CurationCardV2 key={curation.id} curation={curation} curationIdsPath={items} />
                ))}
            </CurationsContainerGrid>
        </FadeUpAnimationWrapper>
    );
}

type CurationGroupsProps = Pick<CurationProps, "curation" | "groups">;

function CurationGroups({ curation, groups }: CurationGroupsProps) {
    const isFadeUpAnimation = useEntityConfigurationValueByKey<boolean>("SETTING.SECTION_FADE_UP_ANIMATION") ?? false;
    const { items } = useBreadcrumbItems(curation.id);

    if (groups.length === 0) return null;

    return (
        <Stack gap={2}>
            {groups.map((group) => (
                <FadeUpAnimationWrapper key={group.id} isEnabled={isFadeUpAnimation}>
                    <CurationGroup name={group.name} curations={group.children} curationIdsPath={items} />
                </FadeUpAnimationWrapper>
            ))}
        </Stack>
    );
}

type CurationSiblingsProps = Pick<CurationProps, "curation" | "curationParent" | "siblings">;

function CurationSiblingList({ curation, curationParent, siblings }: CurationSiblingsProps) {
    const { items } = useBreadcrumbItems(curation.id);
    const isFadeUpAnimation = useEntityConfigurationValueByKey<boolean>("SETTING.SECTION_FADE_UP_ANIMATION") ?? false;
    const curationSiblingTitle = useEntityConfigurationValueByKey("SETTING.CURATION_SIBLING_TITLE");

    if (siblings.length === 0 || !curationParent) return null;

    const parentCurationItem = { label: curationParent.name ?? "" };
    const title = `${curationSiblingTitle} ${parentCurationItem.label}`;

    return (
        <FadeUpAnimationWrapper isEnabled={isFadeUpAnimation}>
            <CurationSiblingListWrapper>
                <Typography variant="headline" color="primary">
                    {title}
                </Typography>
                <CurationsContainerGrid>
                    {siblings.map((curation) => (
                        <CurationCardSibling key={curation.id} curation={curation} curationIdsPath={items} />
                    ))}
                </CurationsContainerGrid>
            </CurationSiblingListWrapper>
        </FadeUpAnimationWrapper>
    );
}

const CurationSiblingListWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: "column",
}));

type CurationGroupProps = { curation: CurationContentItemCurationFragment; curationIdsPath: string[] };

function CurationCardV2({ curation, curationIdsPath }: CurationGroupProps) {
    const { id, slug, name, heroImageAsset } = curation;

    const href = urlCuration({ curationId: id, slug, curationIdsPath });
    const asset = heroImageAsset ? AssetUrl.fromString(heroImageAsset.uri).unwrap() : undefined;

    return <MarketPlaceTileA title={name} imageAssetUrl={asset!} href={href} maxWidth={500} />;
}

function CurationCardSibling({ curation, curationIdsPath }: CurationGroupProps) {
    // We omit the last item in the path which matches the current curation
    // because the breadcrumb can't show the current curation as a parent of its sibling
    const curationIdsPathFromSibling = curationIdsPath.slice(0, -1);

    return <CurationCardV2 curation={curation} curationIdsPath={curationIdsPathFromSibling} />;
}
