"use client";

import { styled } from "@mui/material/styles";
import { ThemeHeroCarousel, ThemeHeroVideo } from "components/HeroCarousel";
import USPsGrid from "components/USPsGrid";
import { SearchV2 } from "containers/SearchV2";
import { useEntityConfigurationValueByKey } from "hooks/useEntityConfiguration";
import useIsMobile from "hooks/useIsMobile";

import { HeroImagesTitle, HeroSearchCard } from "@holibob-packages/ui-core/components";

const SearchText = styled(HeroImagesTitle)({
    textAlign: "center",
});

const SearchCard = () => {
    const isMobile = useIsMobile();

    return (
        <HeroSearchCard>
            <SearchV2 autofocus={!isMobile} showSearchButton />
        </HeroSearchCard>
    );
};

export const HomePageHero = () => {
    const isHeroVideo = useEntityConfigurationValueByKey<boolean>("SETTING.FEATURE_HERO_VIDEO", false);

    const heroText = useEntityConfigurationValueByKey<string>("HOME_PAGE_HERO_TEXT");

    if (isHeroVideo)
        return (
            <ThemeHeroVideo>
                <SearchCard />
            </ThemeHeroVideo>
        );

    return <ThemeHeroCarousel>{heroText ? <SearchText>{heroText}</SearchText> : <SearchCard />}</ThemeHeroCarousel>;
};

export const USPs = () => {
    return (
        <USPWrapper>
            <USPsGrid />
        </USPWrapper>
    );
};

const USPWrapper = styled("div")({
    "@media (orientation: portrait) and (max-width: 600px)": {
        display: "none",
    },
});
