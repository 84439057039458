"use client";

import { Curation, CurationProps } from "containers/Curation/Curation";
import { CurationV2 } from "containers/CurationV2/CurationV2";
import { useEntityConfigurationValueByKey } from "hooks/useEntityConfiguration";

export type CurationPageWrapperProps = CurationProps;
export const CurationPageWrapper = (props: CurationPageWrapperProps) => {
    const withWhiteLabelDesignV2 = useEntityConfigurationValueByKey("SETTING.WHITE_LABEL_DESIGN_V2") ?? false;

    if (!withWhiteLabelDesignV2) return <Curation {...props} />;

    return <CurationV2 {...props} />;
};
