import { ConsumerTripBookingPaidCreateInput } from "@holibob-packages/consumer-trip-event";

export class BookingPaidEvent extends Event {
    static NAME = "booking:paid";

    constructor(readonly details: ConsumerTripBookingPaidCreateInput["meta"]) {
        super(BookingPaidEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }
}
