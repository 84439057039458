export class QuestionnaireChangeOfHeartClickEvent extends Event {
    static NAME = "questionnaire:change-of-heart-click";

    constructor() {
        super(QuestionnaireChangeOfHeartClickEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }

    clone() {
        return new QuestionnaireChangeOfHeartClickEvent();
    }
}
