import { ConsumerTripContentItemProductListType } from "@holibob-packages/graphql-types";

export class StoryClickEvent extends Event {
    static NAME = "story:click";

    constructor(readonly shelfType: ConsumerTripContentItemProductListType) {
        super(StoryClickEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }
}
