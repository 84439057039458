import { useConsumerTripInfo } from "hooks/useConsumerTripInfo";
import { useCallback } from "react";

import { ConsumerTripEventWLSearchCreateInput } from "@holibob-packages/consumer-trip-event";

import { useConsumerTripEventCreate } from "./useConsumerTripEventCreate";

export function useConsumerTripEventWLSearch() {
    const consumerTripEventCreate = useConsumerTripEventCreate();
    const { consumerTripId } = useConsumerTripInfo();

    return useCallback(
        async (meta: ConsumerTripEventWLSearchCreateInput["meta"]) => {
            await consumerTripEventCreate({ meta, consumerTripId, type: "WL_SEARCH" });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}
