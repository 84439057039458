import { useCallback } from "react";

import { ConsumerTripEventCreateInput } from "@holibob-packages/consumer-trip-event";

import { useConsumerTripEventCreateMutation } from "../../../packages/ui-core/src/apiHooks/graphql";

export function useConsumerTripEventCreate() {
    const [consumerTripEventCreateMutation] = useConsumerTripEventCreateMutation();
    return useCallback(
        async (input: ConsumerTripEventCreateInput) => {
            await consumerTripEventCreateMutation({ variables: input, errorPolicy: "ignore" });
        },
        [consumerTripEventCreateMutation]
    );
}
