"use client";

import { HbmlContentRenderer, HbmlContentRendererParams } from "components/Hbml/HbmlContentRenderer";
import { getPageComponentPresets } from "components/Hbml/getPageComponentPresets";
import { HeaderSearchButton } from "components/HeaderSearchButton";
import Page from "containers/Page";
import { DistributionChannelPageContentQuery } from "gql";
import { useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { isTransparentHeaderAllowedForHBML } from "utils/isTransparentHeaderAllowedForHBML";

import { useRouter, usePathname } from "@holibob-packages/ui-core/navigation";

type CustomHbmlHomePageProps = HbmlContentRendererParams & {
    pagePath: string;
    distributionChannelPageContent?: DistributionChannelPageContentQuery["distributionChannelPageContent"];
};

export const CustomHbmlHomePage = ({ pagePath, content, distributionChannelPageContent }: CustomHbmlHomePageProps) => {
    const allowHeaderTransparency = isTransparentHeaderAllowedForHBML(content);
    const searchParams = useSearchParams();
    const router = useRouter();
    const pathname = usePathname();

    useEffect(() => {
        if (searchParams.has("ctid")) {
            const params = new URLSearchParams(searchParams);
            params.delete("ctid");
            router.replace(pathname + (params.size ? "?" + params.toString() : ""));
        }
    }, [searchParams, router, pathname]);

    return (
        <Page
            type="Home"
            title="Home"
            allowHeaderTransparency={allowHeaderTransparency}
            headerConfig={
                distributionChannelPageContent?.placeId
                    ? {
                          withGlobalSearchButton: false,
                          extraHeaderBarButtons: (
                              <HeaderSearchButton placeId={distributionChannelPageContent.placeId} />
                          ),
                      }
                    : undefined
            }
        >
            <HbmlContentRenderer
                content={content}
                hbmlComponentsPreset={getPageComponentPresets({ pagePath, distributionChannelPageContent })}
            />
        </Page>
    );
};
