import { useCallback } from "react";

import type { ConsumerTripEventWLProductClickInput } from "@holibob-packages/consumer-trip-event";

import { useConsumerTripEventRegistration } from "./useConsumerTripEventRegistration";

export function useConsumerTripProductClickEventRegistration() {
    const register = useConsumerTripEventRegistration();
    return useCallback(
        (meta: ConsumerTripEventWLProductClickInput["meta"]) => {
            return register({
                type: "WL_PRODUCT_CLICK",
                meta: meta,
            });
        },
        [register]
    );
}
