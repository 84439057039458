import { useEntityConfigurationByTypes, EntityConfiguration } from "./useEntityConfiguration";

export type USP = {
    title: EntityConfiguration["value"];
    icon: EntityConfiguration["icon"];
    id: EntityConfiguration["id"];
};

const useUsps = (): USP[] => {
    return useEntityConfigurationByTypes("USP")
        .sort((a, b) => (a.subType < b.subType ? -1 : 1))
        .map((usp) => ({
            title: usp.value,
            icon: usp.icon,
            id: usp.id,
        }));
};

export default useUsps;
