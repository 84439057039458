import { makeStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles()({
    svgContainer: {
        display: "inline-block",
        "& > svg": {
            width: "100%",
            height: "100%",
            fill: "currentColor",
        },
    },
});

export function SvgIcon({ children, className: classNameProp, ...props }: $TSFixMe) {
    const { classes, cx } = useStyles();
    const className = cx(classes.svgContainer, classNameProp);

    if (typeof children === "string")
        return <div className={className} dangerouslySetInnerHTML={{ __html: children }} {...props} />;

    return (
        <div className={className} {...props}>
            {children}
        </div>
    );
}

export default SvgIcon;
