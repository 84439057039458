import { useConsumerTripInfo } from "hooks/useConsumerTripInfo";
import { useCallback } from "react";

import {
    ConsumerTripEventWLQuesitonnaireBackClickedCreateInput,
    ConsumerTripEventWLQuesitonnaireClosedCreateInput,
    ConsumerTripEventWLQuesitonnaireInterestSelectedCreateInput,
    ConsumerTripEventWLQuesitonnaireInterestUnselectedCreateInput,
    ConsumerTripEventWLQuesitonnaireNextClickedCreateInput,
    ConsumerTripEventWLQuesitonnaireOpenedCreateInput,
} from "@holibob-packages/consumer-trip-event";

import { useConsumerTripEventCreate } from "./useConsumerTripEventCreate";

function useConsumerTripEventQuesitonnaireOpened() {
    const consumerTripEventCreate = useConsumerTripEventCreate();
    const { consumerTripId } = useConsumerTripInfo();

    return useCallback(
        async (meta: ConsumerTripEventWLQuesitonnaireOpenedCreateInput["meta"]) => {
            await consumerTripEventCreate({
                meta,
                consumerTripId,
                type: "WL_CONSUMER_TRIP_QUESTIONNAIRE_OPENED",
            });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

function useConsumerTripEventQuesitonnaireInterestSelected() {
    const consumerTripEventCreate = useConsumerTripEventCreate();
    const { consumerTripId } = useConsumerTripInfo();

    return useCallback(
        async (meta: ConsumerTripEventWLQuesitonnaireInterestSelectedCreateInput["meta"]) => {
            await consumerTripEventCreate({
                meta,
                consumerTripId,
                type: "WL_CONSUMER_TRIP_QUESTIONNAIRE_INTEREST_SELECTED",
            });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

function useConsumerTripEventQuesitonnaireInterestUnselected() {
    const consumerTripEventCreate = useConsumerTripEventCreate();
    const { consumerTripId } = useConsumerTripInfo();

    return useCallback(
        async (meta: ConsumerTripEventWLQuesitonnaireInterestUnselectedCreateInput["meta"]) => {
            await consumerTripEventCreate({
                meta,
                consumerTripId,
                type: "WL_CONSUMER_TRIP_QUESTIONNAIRE_INTEREST_UNSELECTED",
            });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

function useConsumerTripEventQuesitonnaireNextClicked() {
    const consumerTripEventCreate = useConsumerTripEventCreate();
    const { consumerTripId } = useConsumerTripInfo();

    return useCallback(
        async (meta: ConsumerTripEventWLQuesitonnaireNextClickedCreateInput["meta"]) => {
            await consumerTripEventCreate({
                meta,
                consumerTripId,
                type: "WL_CONSUMER_TRIP_QUESTIONNAIRE_NEXT_CLICKED",
            });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

function useConsumerTripEventQuesitonnaireSubmitted() {
    const consumerTripEventCreate = useConsumerTripEventCreate();
    const { consumerTripId } = useConsumerTripInfo();

    return useCallback(async () => {
        await consumerTripEventCreate({
            meta: {},
            consumerTripId,
            type: "WL_CONSUMER_TRIP_QUESTIONNAIRE_SUBMITTED",
        });
    }, [consumerTripEventCreate, consumerTripId]);
}

function useConsumerTripEventQuesitonnaireChangeOfHeartClicked() {
    const consumerTripEventCreate = useConsumerTripEventCreate();
    const { consumerTripId } = useConsumerTripInfo();

    return useCallback(async () => {
        await consumerTripEventCreate({
            meta: {},
            consumerTripId,
            type: "WL_CONSUMER_TRIP_QUESTIONNAIRE_CHANGE_OF_HEART_CLICKED",
        });
    }, [consumerTripEventCreate, consumerTripId]);
}

function useConsumerTripEventQuesitonnaireClosed() {
    const consumerTripEventCreate = useConsumerTripEventCreate();
    const { consumerTripId } = useConsumerTripInfo();

    return useCallback(
        async (meta: ConsumerTripEventWLQuesitonnaireClosedCreateInput["meta"]) => {
            await consumerTripEventCreate({ meta, consumerTripId, type: "WL_CONSUMER_TRIP_QUESTIONNAIRE_CLOSED" });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

function useConsumerTripEventQuesitonnaireBackClicked() {
    const consumerTripEventCreate = useConsumerTripEventCreate();
    const { consumerTripId } = useConsumerTripInfo();

    return useCallback(
        async (meta: ConsumerTripEventWLQuesitonnaireBackClickedCreateInput["meta"]) => {
            await consumerTripEventCreate({
                meta,
                consumerTripId,
                type: "WL_CONSUMER_TRIP_QUESTIONNAIRE_BACK_CLICKED",
            });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

export function useConsumerTripEventQuestionnaire() {
    const consumerTripEventQuesitonnaireOpened = useConsumerTripEventQuesitonnaireOpened();
    const consumerTripEventQuesitonnaireInterestSelected = useConsumerTripEventQuesitonnaireInterestSelected();
    const consumerTripEventQuesitonnaireInterestUnselected = useConsumerTripEventQuesitonnaireInterestUnselected();
    const consumerTripEventQuesitonnaireNextClicked = useConsumerTripEventQuesitonnaireNextClicked();
    const consumerTripEventQuesitonnaireSubmitted = useConsumerTripEventQuesitonnaireSubmitted();
    const consumerTripEventQuesitonnaireChangeOfHeartClicked = useConsumerTripEventQuesitonnaireChangeOfHeartClicked();
    const consumerTripEventQuesitonnaireClosed = useConsumerTripEventQuesitonnaireClosed();
    const consumerTripEventQuesitonnaireBackClicked = useConsumerTripEventQuesitonnaireBackClicked();

    return {
        consumerTripEventQuesitonnaireOpened,
        consumerTripEventQuesitonnaireInterestSelected,
        consumerTripEventQuesitonnaireInterestUnselected,
        consumerTripEventQuesitonnaireNextClicked,
        consumerTripEventQuesitonnaireSubmitted,
        consumerTripEventQuesitonnaireChangeOfHeartClicked,
        consumerTripEventQuesitonnaireClosed,
        consumerTripEventQuesitonnaireBackClicked,
    };
}
