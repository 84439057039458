import BlockIcon from "@mui/icons-material/Block";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import GroupIcon from "@mui/icons-material/Group";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MapIcon from "@mui/icons-material/Map";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import Grid from "@mui/material/Grid";
import SvgIcon from "@mui/material/SvgIcon";
import Text from "components/Text";
import { EntityConfiguration } from "hooks/useEntityConfiguration";
import useUsps from "hooks/useUsps";

import { Flex } from "@holibob-packages/ui-core/components";
import { makeStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles()((theme) => ({
    container: {
        backgroundColor: "WhiteSmoke",
        padding: theme.spacing(2, 0),
    },
    item: {
        height: 80,
    },
    icon: {
        marginBottom: theme.spacing(1),
        color: theme.palette.buttonPrimary.main,
    },
}));

const icons = {
    CUP: EmojiEventsIcon,
    LIGHTNING: FlashOnIcon,
    MAP: MapIcon,
    CITY: LocationCityIcon,
    PEOPLE: GroupIcon,
    DISCOUNT: MoneyOffIcon,
    CANCEL: BlockIcon,
} as const;

const DefaultIcon = icons.LIGHTNING;

type USPIconProps = {
    icon: EntityConfiguration["icon"];
};

const USPIcon = ({ icon }: USPIconProps) => {
    const { classes } = useStyles();

    let Icon = DefaultIcon;
    if (icon) {
        const { name: iconName, svg: iconSvg } = icon;

        if (iconSvg) {
            return (
                <SvgIcon fontSize="large" className={classes.icon}>
                    <g dangerouslySetInnerHTML={{ __html: iconSvg }}></g>
                </SvgIcon>
            );
        } else if (iconName && Object.keys(icons).includes(iconName)) {
            Icon = icons[iconName as keyof typeof icons];
        }
    }

    return <Icon fontSize="large" className={classes.icon} />;
};

const USPsGrid = () => {
    const { classes } = useStyles();
    const usps = useUsps();

    if (!usps.length) return null;

    return (
        <Grid container justifyContent="center" className={classes.container}>
            {usps.map(({ title, icon, id }) => {
                return (
                    <Grid item xs={6} sm={6} lg={3} className={classes.item} key={id}>
                        <Flex data-testid="USP" column middle center height="100%">
                            <USPIcon icon={icon}></USPIcon>
                            <Text variant="body2">{title}</Text>
                        </Flex>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default USPsGrid;
