import { ConsumerTripContentItemProductListType } from "@holibob-packages/graphql-types";

import { ProductItem } from "../types";

export class ShelfProductClickEvent extends Event {
    static NAME = "shelf:product-click";

    constructor(
        readonly details: {
            item: ProductItem;
            position: number;
            shelfType: ConsumerTripContentItemProductListType;
        }
    ) {
        super(ShelfProductClickEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }
}
