import { useConsumerTripInfo } from "hooks/useConsumerTripInfo";
import { useCallback } from "react";

import type { HBMLEventComponentShelfScrolledInput } from "@holibob-packages/consumer-trip-event";

import { useConsumerTripEventCreateMutation } from "../../gql";

export function useHBMLComponentShelfScrolledEvent() {
    const { consumerTripId } = useConsumerTripInfo();
    const [consumerTripEventCreate] = useConsumerTripEventCreateMutation();

    return useCallback(
        (meta: HBMLEventComponentShelfScrolledInput["meta"]) => {
            const event: HBMLEventComponentShelfScrolledInput = {
                consumerTripId,
                type: "HBML_COMPONENT_SHELF_SCROLLED",
                meta: meta,
            };

            void consumerTripEventCreate({ variables: event });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}
