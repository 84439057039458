import ApiErrorAlert from "components/ApiErrorAlert";
import { BookingProductCardV2 } from "containers/BookingProductCardV2";
import { useViewItemList } from "containers/GoogleAnalytics/googleAnalyticsEvents";
import { CurationProductFragment, useCurationProductsQuery } from "gql";
import { ProductUrlGenerator } from "hooks/useProductUrlGenerator";

import { CurationProductsGrid, CurationProductsGridSkeleton } from "@holibob-packages/ui-core/components";

import { useAnalyticsEntityData } from "../ProductFilteredList/hooks";

export type CurationProductsGridV2Props = {
    curationId: string;
    urlGenerator: ProductUrlGenerator;
};

export function CurationProductsGridV2({ curationId, urlGenerator }: CurationProductsGridV2Props) {
    const { loading, error, data } = useCurationProductsQuery({ variables: { curationId } });
    const products = data?.productList?.nodes;

    const { entityId, entityType } = useAnalyticsEntityData();
    useViewItemList(entityId, entityType, products);

    if (error) return <ApiErrorAlert error={error} />;

    return <CurationProductsContainer loading={loading} products={products} urlGenerator={urlGenerator} />;
}

type CurationProductsContainerProps = Pick<CurationProductsGridV2Props, "urlGenerator"> & {
    loading: boolean;
    products?: CurationProductFragment[];
};

function CurationProductsContainer({ loading, products, urlGenerator }: CurationProductsContainerProps) {
    if (loading) return <CurationProductsGridSkeleton />;

    if (!products?.length) return null;

    return (
        <CurationProductsGrid
            products={products}
            productHrefFactory={urlGenerator}
            ProductCardComponent={BookingProductCardV2}
        />
    );
}
