"use client";

import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { HbmlContentRenderer } from "components/Hbml/HbmlContentRenderer";
import ProductFilteredList from "containers/ProductFilteredList";
import { ProductFilteredGrid, ProductFilteredGridProps } from "containers/ProductFilteredList/ProductFilteredGrid";
import { UseProductFilteredListCreateContextParams } from "containers/ProductFilteredList/hooks";
import { useEntityConfigurationValueByKey } from "hooks/useEntityConfiguration";
import { useProductUrlGenerator } from "hooks/useProductUrlGenerator";

import type { Hbml } from "@holibob-packages/hbml";
import { HbmlComponents, HbmlRenderer, HeroImage, FadeUpAnimationWrapper } from "@holibob-packages/ui-core/components";
import { Flex } from "@holibob-packages/ui-core/components";
import { CurationCard } from "@holibob-packages/ui-core/curation";
import { CurationProductClickEvent } from "@holibob-packages/ui-core/custom-events";
import { urlCuration } from "@holibob-packages/url";
import { AssetUrl } from "@holibob-packages/vault";

import { CurationsContainer } from "../../components/CurationsContainer";
import { LayoutContent } from "../../components/Layout";
import {
    CurationContentItemCurationFragment,
    CurationContentItemGroupFragment,
    CurationContentItemCurationParentFragment,
    CurationContentQuery,
} from "../../gql";
import { CurationBreadcrumbs, useBreadcrumbItems } from "./CurationBreadcrumbs";
import { CurationGroup } from "./CurationGroup";
import { CurationSiblings } from "./CurationSiblings";

export type CurationProps = {
    curation: NonNullable<CurationContentQuery["curationContent"]>["curation"];
    curations: CurationContentItemCurationFragment[];
    groups: CurationContentItemGroupFragment[];
    siblings: CurationContentItemCurationFragment[];
    curationParent: CurationContentItemCurationParentFragment | null;
    showHeader?: boolean;
    showProductListTitle?: boolean;
    showProductList?: boolean;
};

const Wrapper = styled(LayoutContent)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
}));

const Title = styled("h1")(({ theme }) => ({
    fontSize: "2.5rem",
    margin: 0,
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
}));

const TREE_IDS: UseProductFilteredListCreateContextParams["treeIds"] = ["CATEGORY", "ATTRIBUTE"];

export function Curation({
    curation,
    curations,
    groups,
    siblings,
    curationParent,
    showHeader = true,
    showProductListTitle = true,
    showProductList = true,
}: CurationProps) {
    const { id, name, endContent } = curation;
    const filter = { curationSlugId: id };
    const isProductsListGridMode = useEntityConfigurationValueByKey<boolean>("SETTING.CURATION_GRID") ?? false;
    const withSectionFadeUpAnimation =
        useEntityConfigurationValueByKey<boolean>("SETTING.SECTION_FADE_UP_ANIMATION") ?? false;
    const { items } = useBreadcrumbItems(id);
    const ProductsComponent = isProductsListGridMode ? ProductFilteredGrid : ProductFilteredList;
    const productUrlGenerator = useProductUrlGenerator({ curationIdsPath: items });

    const onProductClick: ProductFilteredGridProps["onProductClick"] = (event, data) => {
        event.target.dispatchEvent(new CurationProductClickEvent(data));
    };

    return (
        <>
            {showHeader && curation.heroImageAsset && (
                <HeroImage src={curation.heroImageAsset.uri} name={curation.name} />
            )}
            <Wrapper>
                {showHeader && (
                    <Flex gap={10} column>
                        <CurationBreadcrumbs currentCurationId={id} />
                        <Title>{curation.name}</Title>
                        {curation.description && (
                            <div>
                                <HbmlRenderer
                                    content={curation.description.raw as Hbml}
                                    components={HbmlComponents.HbmlStandardPreset}
                                />
                            </div>
                        )}
                    </Flex>
                )}
                {showProductList && (
                    <ProductsComponent
                        filter={filter}
                        treeIds={TREE_IDS}
                        searchTerm={name}
                        showTitle={showProductListTitle}
                        showSearch={false}
                        showFilters={false}
                        showFiltersTree={!isProductsListGridMode}
                        urlGenerator={productUrlGenerator}
                        onProductClick={onProductClick}
                        hideUnlessProducts
                    />
                )}
                {curations.length > 0 && (
                    <FadeUpAnimationWrapper isEnabled={withSectionFadeUpAnimation}>
                        <CurationsContainer>
                            {curations.map((entry) => {
                                const { id, name, slug, heroImageAsset } = entry;
                                const image = heroImageAsset
                                    ? AssetUrl.fromString(heroImageAsset.uri).unwrap()
                                    : undefined;
                                return (
                                    <CurationCard
                                        key={id}
                                        href={urlCuration({ curationId: id, slug, curationIdsPath: items })}
                                        name={name}
                                        image={image}
                                    />
                                );
                            })}
                        </CurationsContainer>
                    </FadeUpAnimationWrapper>
                )}
                {groups.length > 0 && (
                    <Stack gap={2}>
                        {groups.map((group) => (
                            <FadeUpAnimationWrapper key={group.id} isEnabled={withSectionFadeUpAnimation}>
                                <CurationGroup name={group.name} curations={group.children} curationIdsPath={items} />
                            </FadeUpAnimationWrapper>
                        ))}
                    </Stack>
                )}
                {siblings.length > 0 && curationParent && (
                    <FadeUpAnimationWrapper isEnabled={withSectionFadeUpAnimation}>
                        <CurationSiblings
                            currentCurationId={id}
                            curationSiblings={siblings}
                            parentName={curationParent.name ?? ""}
                            curationIdsPath={items}
                        />
                    </FadeUpAnimationWrapper>
                )}
                {endContent?.raw && <HbmlContentRenderer content={endContent.raw as Hbml} />}
            </Wrapper>
        </>
    );
}
