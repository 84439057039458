import { ConsumerTripEventWLQuesitonnaireClosedCreateInput } from "@holibob-packages/consumer-trip-event";

export class QuestionnaireCloseEvent extends Event {
    static NAME = "questionnaire:close";

    constructor(readonly details: ConsumerTripEventWLQuesitonnaireClosedCreateInput["meta"]) {
        super(QuestionnaireCloseEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }

    clone() {
        return new QuestionnaireCloseEvent(this.details);
    }
}
