import { ConsumerTripEventWLPersonalizationShelfDisplayedCreateInput } from "@holibob-packages/consumer-trip-event";

export class ShelfDisplayEvent extends Event {
    static NAME = "shelf:display";

    constructor(readonly details: ConsumerTripEventWLPersonalizationShelfDisplayedCreateInput["meta"]) {
        super(ShelfDisplayEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }
}
