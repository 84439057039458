import { ConsumerTripContentItemProductListType } from "@holibob-packages/graphql-types";

export class StoryProductsScrollEvent extends Event {
    static NAME = "story:product-scroll";

    constructor(readonly shelfType: ConsumerTripContentItemProductListType) {
        super(StoryProductsScrollEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }
}
