"use client";

import { styled } from "@mui/material/styles";
import React from "react";

import { Typography } from "@holibob-packages/ui-core/components";

const MainErrorContainer = styled("div")(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
}));

export function ErrorComponent({ errorMessage }: { errorMessage: string }) {
    return (
        <MainErrorContainer>
            <Typography variant="headline" size="small" component="h1" isResponsive={true}>
                {errorMessage}
            </Typography>
        </MainErrorContainer>
    );
}
