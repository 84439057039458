export class QuestionnaireSubmitEvent extends Event {
    static NAME = "questionnaire:submit";

    constructor() {
        super(QuestionnaireSubmitEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }

    clone() {
        return new QuestionnaireSubmitEvent();
    }
}
