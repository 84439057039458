import { useConsumerTripInfo } from "hooks/useConsumerTripInfo";
import { useCallback } from "react";

import { ConsumerTripBookingPaidCreateInput } from "@holibob-packages/consumer-trip-event";

import { useConsumerTripEventCreateMutation } from "../../gql";

export function useConsumerTripBookingPaidEvent() {
    const [mutate] = useConsumerTripEventCreateMutation();
    const { consumerTripId } = useConsumerTripInfo();

    return useCallback(
        ({ itemList, currency, price }: ConsumerTripBookingPaidCreateInput["meta"]) => {
            return mutate({
                variables: {
                    consumerTripId,
                    type: "WL_BOOKING_PAID",
                    meta: {
                        itemList,
                        currency,
                        price,
                    },
                },
            });
        },
        [consumerTripId, mutate]
    );
}
