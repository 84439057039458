import { styled } from "@mui/material/styles";

import { CurationCard } from "@holibob-packages/ui-core/curation";
import { urlCuration } from "@holibob-packages/url";
import { AssetUrl } from "@holibob-packages/vault";

import { CurationsContainer } from "../../components/CurationsContainer";
import { CurationContentItemGroupFragment } from "../../gql";

const Wrapper = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(2),
    flexDirection: "column",
    maxWidth: "100%",
}));

const Title = styled("h2")(({ theme }) => ({
    fontSize: 24,
    margin: 0,
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
}));

export type CurationGroupProps = Pick<CurationContentItemGroupFragment, "name"> & {
    curations: CurationContentItemGroupFragment["children"];
    curationIdsPath: string[];
} & React.HTMLAttributes<HTMLDivElement>;

export const CurationGroup = ({ name, curations, curationIdsPath, ...props }: CurationGroupProps) => {
    return (
        <Wrapper {...props}>
            <Title>{name}</Title>
            <CurationsContainer>
                {curations.map((entry) => {
                    const { id, name, slug, heroImageAsset } = entry;
                    const image = heroImageAsset ? AssetUrl.fromString(heroImageAsset.uri).unwrap() : undefined;
                    return (
                        <CurationCard
                            key={id}
                            href={urlCuration({ curationId: id, slug, curationIdsPath })}
                            name={name}
                            image={image}
                        />
                    );
                })}
            </CurationsContainer>
        </Wrapper>
    );
};
