import { useConsumerTripInfo } from "hooks/useConsumerTripInfo";
import { useCallback } from "react";

import {
    ConsumerTripEventWLStoryClickedCreateInput,
    ConsumerTripEventWLStoryDisplayedCreateInput,
    ConsumerTripEventWLStoryScrolledCreateInput,
} from "@holibob-packages/consumer-trip-event";

import { useConsumerTripEventCreate } from "./useConsumerTripEventCreate";

function useConsumerTripEventCreateStoryClicked() {
    const consumerTripEventCreate = useConsumerTripEventCreate();
    const { consumerTripId } = useConsumerTripInfo();

    return useCallback(
        async (shelfType: ConsumerTripEventWLStoryClickedCreateInput["meta"]["shelfType"]) => {
            await consumerTripEventCreate({
                consumerTripId,
                meta: { shelfType },
                type: "WL_CONSUMER_TRIP_STORY_CLICKED",
            });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

function useConsumerTripEventCreateStoryScrolled() {
    const consumerTripEventCreate = useConsumerTripEventCreate();
    const { consumerTripId } = useConsumerTripInfo();

    return useCallback(
        async (shelfType: ConsumerTripEventWLStoryScrolledCreateInput["meta"]["shelfType"]) => {
            await consumerTripEventCreate({
                meta: { shelfType },
                consumerTripId,
                type: "WL_CONSUMER_TRIP_STORY_SCROLLED",
            });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

function useConsumerTripEventCreateStoryDisplayed() {
    const consumerTripEventCreate = useConsumerTripEventCreate();
    const { consumerTripId } = useConsumerTripInfo();

    return useCallback(
        async (meta: ConsumerTripEventWLStoryDisplayedCreateInput["meta"]) => {
            await consumerTripEventCreate({ meta, consumerTripId, type: "WL_CONSUMER_TRIP_STORY_DISPLAYED" });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

export function useConsumerTripEventStory() {
    const consumerTripEventCreateStoryClicked = useConsumerTripEventCreateStoryClicked();
    const consumerTripEventCreateStoryScrolled = useConsumerTripEventCreateStoryScrolled();
    const consumerTripEventCreateStoryDisplayed = useConsumerTripEventCreateStoryDisplayed();

    return {
        consumerTripEventCreateStoryClicked,
        consumerTripEventCreateStoryScrolled,
        consumerTripEventCreateStoryDisplayed,
    };
}
