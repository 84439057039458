import { useEntityConfigurationValueByKey } from "hooks/useEntityConfiguration";

import { CurationContentItemCurationFragment } from "../../gql";
import { CurationGroup } from "./CurationGroup";

export type CurationSiblingProps = {
    curationSiblings: CurationContentItemCurationFragment[];
    currentCurationId: string;
    parentName: string;
    curationIdsPath: string[];
} & React.HTMLAttributes<HTMLDivElement>;

export const CurationSiblings = ({ curationSiblings, parentName, curationIdsPath, ...props }: CurationSiblingProps) => {
    const curationSiblingTitle = useEntityConfigurationValueByKey("SETTING.CURATION_SIBLING_TITLE");
    const parentCurationItem = { label: parentName };
    const title = `${curationSiblingTitle} ${parentCurationItem.label}`;
    // We omit the last item in the path which matches the current curation
    // because the breadcrumb can't show the current curation as a parent of its sibling
    const curationIdsPathFromSibling = curationIdsPath.slice(0, -1);

    return (
        <CurationGroup
            name={title}
            curations={curationSiblings}
            curationIdsPath={curationIdsPathFromSibling}
            {...props}
        />
    );
};
