import CircularProgress from "@mui/material/CircularProgress";
import ApiErrorAlert from "components/ApiErrorAlert";
import Text, { TextTitle2 } from "components/Text";
import { useViewItemList } from "containers/GoogleAnalytics/googleAnalyticsEvents";
import Paganate from "containers/Paganate";
import { useNextTranslation } from "hooks/useTranslation";
import { useSearchParams } from "next/navigation";

import { ErrorBoundary, Spacer } from "@holibob-packages/ui-core/components";
import { ProductsContainerGridSkeleton } from "@holibob-packages/ui-core/product";

import { ProductGrid, ProductGridProps } from "../ProductGrid";
import ProductFilteredListFilters from "./ProductFilteredListFilters";
import ProductFilteredListLayout from "./ProductFilteredListLayout";
import ProductFilteredListSearch from "./ProductFilteredListSearch";
import { ProductFilteredListFilterTrees } from "./Tree/ProductFilteredListFilterTrees";
import {
    ProductFilteredListStateContext,
    useProductFilteredListCreateContext,
    UseProductFilteredListCreateContextParams,
    useProductFilteredListError,
    useProductFilteredListHeader,
    useProductFilteredListLoading,
    useProductFilteredListPaginationProps,
    useProductFilteredListProducts,
    useProductFilteredListSearchTerm,
    useProductFilteredListTitle,
} from "./hooks";

type ProductFilteredGridContentProps = Pick<
    ProductFilteredGridProps,
    "showFiltersTree" | "showFilters" | "showSearch" | "showTitle" | "urlGenerator" | "onProductClick"
>;

function ProductFilteredGridContent({
    showFiltersTree = true,
    showFilters = true,
    showSearch = true,
    showTitle = true,
    urlGenerator,
    onProductClick,
}: ProductFilteredGridContentProps) {
    const products = useProductFilteredListProducts();
    const error = useProductFilteredListError();
    const paginationProps = useProductFilteredListPaginationProps();
    const searchTerm = useProductFilteredListSearchTerm();
    const loading = useProductFilteredListLoading();
    let header = useProductFilteredListHeader();
    let title = useProductFilteredListTitle();

    const searchParams = useSearchParams();
    const curationId = searchParams.get("curationId");
    const placeId = searchParams.get("placeId");
    const categoryId = searchParams.get("categoryId");

    let entityId = undefined;
    let entityType = undefined;
    if (curationId) {
        entityId = curationId;
        entityType = "Curation";
    } else if (placeId) {
        entityId = placeId;
        entityType = "Place";
    } else if (categoryId) {
        entityId = categoryId;
        entityType = "Category";
    }

    useViewItemList(entityId, entityType, products);

    const [t] = useNextTranslation("product");
    const noProductFoundMessage = t("message.noProductFound");

    if (error) return <ApiErrorAlert error={error} />;

    if (typeof title === "string") title = <TextTitle2>{title}</TextTitle2>;
    if (!header) header = title;

    let productList = null;
    if (loading) productList = <CircularProgress color="primary" />;
    else if (products.length)
        productList = <ProductGrid products={products} onProductClick={onProductClick} urlGenerator={urlGenerator} />;
    else productList = <Text variant="body2">{noProductFoundMessage}</Text>;

    const content = (
        <div>
            {showSearch && (
                <>
                    <ProductFilteredListSearch />
                    <Spacer sm />
                </>
            )}

            {showFilters && (
                <>
                    <ProductFilteredListFilters />
                    <Spacer sm />
                </>
            )}
            {productList}
        </div>
    );

    const footer = loading ? null : <Paganate {...paginationProps} searchTerm={searchTerm} />;

    const sidebar = showFiltersTree ? <ProductFilteredListFilterTrees /> : undefined;

    return (
        <ProductFilteredListLayout
            header={showTitle ? header : undefined}
            sidebar={sidebar}
            content={content}
            footer={footer}
        />
    );
}

export type ProductFilteredGridProps = UseProductFilteredListCreateContextParams &
    Pick<ProductGridProps, "onProductClick"> & {
        urlGenerator: (data: { id: string; slug?: string | null }) => string;
    };

export function ProductFilteredGrid(props: ProductFilteredGridProps) {
    const { urlGenerator, ...restProps } = props;
    const value = useProductFilteredListCreateContext(restProps);

    const { productsResponse, hide, loading } = value;
    const { error, hasRootErrors } = productsResponse;

    if (hide) return null;
    if (error && hasRootErrors) return <ApiErrorAlert error={error} />;

    if (loading) {
        return <ProductsContainerGridSkeleton />;
    }

    return (
        <ErrorBoundary>
            <ProductFilteredListStateContext.Provider value={value}>
                <ProductFilteredGridContent urlGenerator={urlGenerator} {...restProps} />
            </ProductFilteredListStateContext.Provider>
        </ErrorBoundary>
    );
}
