import(/* webpackMode: "eager", webpackExports: ["CurationPageWrapper"] */ "/home/runner/work/holibob/holibob/booking-web/app/[locale]/curation/[curationId]/[slug]/CurationPageWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CustomHbmlHomePage"] */ "/home/runner/work/holibob/holibob/booking-web/app/[locale]/CustomHbmlHomePage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomePageHero","USPs"] */ "/home/runner/work/holibob/holibob/booking-web/app/[locale]/DefaultHomePageComponents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorComponent"] */ "/home/runner/work/holibob/holibob/booking-web/components/ErrorComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LayoutContent"] */ "/home/runner/work/holibob/holibob/booking-web/components/Layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/holibob/holibob/booking-web/containers/FeaturedProducts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/holibob/holibob/booking-web/containers/Page/Page.tsx");
