import { ProductItem } from "../types";

export class DestinationProductSearchClickEvent extends Event {
    static NAME = "search:destination-product-click";

    constructor(
        readonly details: {
            item: ProductItem;
            position: number;
        }
    ) {
        super(DestinationProductSearchClickEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }
}
