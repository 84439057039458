import { ConsumerTripQuestionnaireOpenTrigger } from "@holibob-packages/consumer-trip-event";

export class QuestionnaireOpenEvent extends Event {
    static NAME = "questionnaire:open";

    constructor(readonly details: { questionnaireOpenTrigger: ConsumerTripQuestionnaireOpenTrigger }) {
        super(QuestionnaireOpenEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }

    clone() {
        return new QuestionnaireOpenEvent(this.details);
    }
}
