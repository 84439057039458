import { ConsumerTripEventWLQuesitonnaireBackClickedCreateInput } from "@holibob-packages/consumer-trip-event";

export class QuestionnaireBackClickEvent extends Event {
    static NAME = "questionnaire:back-click";

    constructor(readonly details: ConsumerTripEventWLQuesitonnaireBackClickedCreateInput["meta"]) {
        super(QuestionnaireBackClickEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }

    clone() {
        return new QuestionnaireBackClickEvent(this.details);
    }
}
