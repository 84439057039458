import { ConsumerTripContentItemProductListType } from "@holibob-packages/graphql-types";

export class ShelfFirstScrollEvent extends Event {
    static NAME = "shelf:first-scroll";

    constructor(readonly shelfType: ConsumerTripContentItemProductListType) {
        super(ShelfFirstScrollEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }
}
