import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Logo from "components/Logo";
import SvgIcon from "components/SvgIcon";
import Text, { TextSubtitle2, TextTitle4 } from "components/Text";
import { useBrand } from "hooks/useBrand";
import {
    EntityConfiguration,
    EntityConfigurationIcon,
    useEntityConfigurationByTypes,
    useEntityConfigurationValueByKey,
} from "hooks/useEntityConfiguration";
import { useNextTranslation } from "hooks/useTranslation";
import { useMemo } from "react";

import { Flex, Spacer } from "@holibob-packages/ui-core/components";
import { Link } from "@holibob-packages/ui-core/navigation";
import { makeStyles } from "@holibob-packages/ui-core/style";
import { AssetUrl, assetUrlToVaultUrl } from "@holibob-packages/vault";

type SocialIcon = {
    platform: EntityConfiguration["subType"];
    url: EntityConfiguration["value"];
    svg: NonNullable<EntityConfigurationIcon>["svg"];
};

function useSocialIcons(): SocialIcon[] {
    const configs = useEntityConfigurationByTypes("SOCIAL");
    return useMemo(() => {
        return configs.reduce((acc: SocialIcon[], config) => {
            const { subType, value, icon } = config;

            if (value && icon?.svg) {
                acc.push({ platform: subType, url: value, svg: icon.svg });
            }
            return acc;
        }, []);
    }, [configs]);
}

const useStyles = makeStyles()((theme) => ({
    bottomContainer: {
        paddingTop: 80,
        paddingBottom: 80,
        backgroundColor: theme.palette.footer.main,
        color: theme.palette.footer.contrastText,
        borderTop: "1px solid silver",
    },
    showConsentModalButton: {
        color: theme.palette.footer.contrastText,
    },
    bottomContent: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        textAlign: "center",
    },
    columnTitle: {
        color: theme.palette.footer.contrastText,
        lineHeight: 1,
        position: "relative",
        marginBottom: 10,
    },
    footerColumn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: 10,
    },
    social: {
        height: 40,
        width: 40,
        padding: 4,
        margin: 4,
        transition: "all 0.5s",
        "&:hover": {
            opacity: 0.8,
        },
    },
    link: {
        position: "relative",
        marginBottom: 15,
        "&::after": {
            content: '""',
            transition: "opacity 0.5s",
            width: "100%",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: -3,
            borderWidth: "0 0 2px",
            borderStyle: "solid",
            borderColor: theme.palette.footer.main,
            opacity: 0.5,
        },
        "&:hover::after": {
            opacity: 1,
        },
    },
    label: {
        color: "inherit",
    },
}));
const PoweredBy = () => {
    const usePoweredBy = useEntityConfigurationValueByKey("SETTING.POWERED_BY");
    if (usePoweredBy) {
        return (
            <Flex middle column>
                <Text small>Powered By</Text>
                <Text
                    variant="h4"
                    bold
                    href="https://holibob.tech"
                    newTab
                    style={{ fontFamily: "Gill Sans, Montserrat" }}
                >
                    holibob
                </Text>
                <Spacer xxs />
                <Text small href="/holibob-privacy-policy" newTab>
                    Privacy Policy
                </Text>
            </Flex>
        );
    }
    return null;
};

type FooterColumnProps = {
    children: React.ReactNode;
    title?: string;
};
const FooterColumn = ({ children, title }: FooterColumnProps) => {
    const { classes, cx } = useStyles();
    return (
        <Box className={classes.footerColumn}>
            {title && (
                <TextTitle4 bold className={cx(classes.columnTitle)}>
                    {title}
                </TextTitle4>
            )}
            {children}
        </Box>
    );
};

const FooterCopyright = () => {
    const { classes } = useStyles();
    const yearString = new Date().getFullYear();
    const copyrightString = `© 2020-${yearString} Holibob Limited.`;
    return <TextSubtitle2 className={classes.label}>{copyrightString}</TextSubtitle2>;
};

const SocialIcon = ({ url, svg }: SocialIcon) => {
    const { classes } = useStyles();

    return (
        <Link newTab href={url}>
            <SvgIcon className={classes.social}>{svg}</SvgIcon>
        </Link>
    );
};
const Socials = () => {
    const socialIcons = useSocialIcons();
    const [t] = useNextTranslation("footer");

    if (socialIcons.length) {
        return (
            <SocialWrapper>
                <Spacer sm />
                <Text bold>{t("label.followUs")}</Text>
                <SocialIconsWrapper>
                    {socialIcons.map((socialIcon) => (
                        <SocialIcon key={socialIcon.url} {...socialIcon} />
                    ))}
                </SocialIconsWrapper>
            </SocialWrapper>
        );
    }
    return null;
};

const SocialWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
});
const SocialIconsWrapper = styled("div")({
    display: "flex",
    alignItems: "center",
});

type ContactProps = {
    label: string;
    text?: string;
    link?: string;
};
const Contact = ({ label, text, link }: ContactProps) => {
    const { classes } = useStyles();
    if (!text) return null;
    return (
        <Box display="flex">
            <Text gutterBottom>
                <TextSubtitle2 component="span" className={classes.label}>
                    {label}
                </TextSubtitle2>
                <TextSubtitle2 component="span" href={link ?? undefined} className={classes.label}>
                    {text}
                </TextSubtitle2>
            </Text>
        </Box>
    );
};

const Contacts = () => {
    const [t] = useNextTranslation("footer");
    const label = t("label.contact");
    const telephoneLabel = t("label.telephone");
    const emailLabel = t("label.email");

    const showContacts = useEntityConfigurationValueByKey("SETTING.FOOTER_CONTACT");
    const contactPhone = useEntityConfigurationValueByKey<string>("CONTACT.PHONE");
    const contactEmail = useEntityConfigurationValueByKey<string>("CONTACT.EMAIL");

    if (!showContacts) return null;

    return (
        <FooterColumn title={label}>
            <Contact label={telephoneLabel} text={contactPhone} />
            <Contact label={emailLabel} text={contactEmail} link={`mailto:${contactEmail}`} />
        </FooterColumn>
    );
};

const FooterLogo = () => {
    const brand = useBrand();
    const src = assetUrlToVaultUrl(AssetUrl.fromString(brand.footerLogo.uri).unwrap()).toString();
    return (
        <Logo
            name="footer-logo"
            src={src}
            paddingTop={brand.headerLogoPaddingTop}
            paddingBottom={brand.headerLogoPaddingBottom}
        />
    );
};

export const Footer = () => {
    const { classes } = useStyles();
    const [t] = useNextTranslation("footer");

    return (
        <Box className={classes.bottomContainer}>
            <Grid container spacing={10}>
                <Grid item md={4} xs={12}>
                    <FooterColumn>
                        <FooterLogo />
                    </FooterColumn>
                </Grid>
                <Grid item md={4} xs={12}>
                    <FooterColumn>
                        <Contacts />
                        <Socials />
                    </FooterColumn>
                </Grid>
                <Grid item md={4} xs={12}>
                    <FooterColumn>
                        <PoweredBy />
                        <Spacer sm />
                        <FooterCopyright />
                        <Spacer sm />
                        <Button
                            className={classes.showConsentModalButton}
                            onClick={() => window.klaro?.show()}
                            variant="text"
                        >
                            {t("label.showConsentModal")}
                        </Button>
                    </FooterColumn>
                </Grid>
            </Grid>
        </Box>
    );
};
