import { ProductUrlGenerator } from "hooks/useProductUrlGenerator";
import { HTMLAttributes } from "react";

import { ProductsContainerGrid } from "@holibob-packages/ui-core/product";
import { ProductItem } from "@holibob-packages/ui-core/types";

import { ProductCardNew } from "./ProductCardNew";

export type ProductGridProps = {
    products: $TSFixMe[];
    urlGenerator: ProductUrlGenerator;
    onProductClick?: (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        data: { item: ProductItem; position: number }
    ) => void;
} & HTMLAttributes<HTMLDivElement>;

export const ProductGrid = ({ products, urlGenerator, onProductClick, ...props }: ProductGridProps) => {
    return (
        <ProductsContainerGrid {...props}>
            {products.map((product: $TSFixMe, position) => {
                return (
                    <ProductCardNew
                        key={product.id}
                        showDescription={false}
                        href={urlGenerator(product)}
                        product={product}
                        onClick={(event, item) => onProductClick?.(event, { item, position })}
                    />
                );
            })}
        </ProductsContainerGrid>
    );
};
