import { useConsumerTripInfo } from "hooks/useConsumerTripInfo";
import { useCallback } from "react";

import type {
    ConsumerTripEventCreateInput,
    ConsumerTripEventWLPersonalizationShelfDisplayedCreateInput,
} from "@holibob-packages/consumer-trip-event";

import { useConsumerTripEventCreateMutation } from "../../gql";

export function usePersonalizationShelfDisplayedEvent() {
    const { consumerTripId } = useConsumerTripInfo();
    const [consumerTripEventCreate] = useConsumerTripEventCreateMutation();

    return useCallback(
        (meta: ConsumerTripEventWLPersonalizationShelfDisplayedCreateInput["meta"]) => {
            const event: ConsumerTripEventCreateInput = {
                consumerTripId,
                type: "WL_PERSONALIZATION_SHELF_DISPLAYED",
                meta,
            };

            void consumerTripEventCreate({ variables: event });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}
