import { useAppState } from "hooks/useAppState";
import { useEffect } from "react";

export const useLoadingBlanker = (loadingProp = false) => {
    const [state, dispatch] = useAppState();

    useEffect(() => {
        dispatch({
            type: loadingProp ? "FULL_PAGE_LOADING_START" : "FULL_PAGE_LOADING_STOP",
        });
    }, [loadingProp, dispatch]);

    return state.loadingBlanker;
};

export default useLoadingBlanker;
