"use client";

import ApiErrorAlert from "components/ApiErrorAlert";
import { Select } from "components/Form/SelectField";
import Text from "components/Text";
import Paganate from "containers/Paganate";
import { ProductGrid } from "containers/ProductGrid";
import useFeaturedProducts from "hooks/useFeaturedProducts";
import useLoading from "hooks/useLoading";
import { useProductUrlGenerator } from "hooks/useProductUrlGenerator";
import { useNextTranslation } from "hooks/useTranslation";
import { useCallback } from "react";

import { Spacer } from "@holibob-packages/ui-core/components";
import { CurationProductClickEvent } from "@holibob-packages/ui-core/custom-events";
import { ProductsContainerGridSkeleton } from "@holibob-packages/ui-core/product";

import { ProductFilteredGridProps } from "./ProductFilteredList/ProductFilteredGrid";

export const FeaturedProductSort = ({ sort, onChange: onChangeProp }: $TSFixMe) => {
    const [t] = useNextTranslation("product");
    const nameAscLabel = t("label.nameAsc");
    const guidePriceAscLabel = t("label.guidePriceAsc");
    const guidePriceDescLabel = t("label.guidePriceDesc");
    const sortLabel = t("label.sort");

    const options = [
        { value: "name:asc", label: nameAscLabel },
        { value: "guidePrice:asc", label: guidePriceAscLabel },
        { value: "guidePrice:desc", label: guidePriceDescLabel },
    ];

    const sortKeys = sort ? Object.keys(sort).map((key) => `${key}:${sort[key]}`) : [];
    const value = sortKeys.length ? sortKeys[0] : "name:asc";

    const onChange = useCallback(
        (value: string) => {
            const [key, dir] = value.split(":");
            const sort = { [key]: dir };
            onChangeProp({ sort });
        },
        [onChangeProp]
    );
    return <Select label={sortLabel} value={value} options={options} onChange={onChange} variant="outlined" />;
};

export const FeaturedProducts = ({ pageSize = 27 }) => {
    const [t] = useNextTranslation("product");
    const ourRecommendationsLabel = t("label.ourRecommendations");
    const handpickedSelectionLabel = t("label.handpickedSelection");
    const productUrlGenerator = useProductUrlGenerator();

    const { hasRootErrors, error, loading, products, nextPage, prevPage, onChange, pagination } = useFeaturedProducts({
        pageSize,
    });

    useLoading(loading);

    const onProductClick: ProductFilteredGridProps["onProductClick"] = (event, data) => {
        event.target.dispatchEvent(new CurationProductClickEvent(data));
    };

    if (hasRootErrors && error) return <ApiErrorAlert error={error} />;

    return (
        <>
            <>
                <Text variant={"h5"}>{ourRecommendationsLabel}</Text>
                <Text variant={"body2"}>{handpickedSelectionLabel}</Text>
                <Spacer />
            </>

            {loading ? (
                <ProductsContainerGridSkeleton numberOfCards={pageSize} />
            ) : (
                <ProductGrid products={products} urlGenerator={productUrlGenerator} onProductClick={onProductClick} />
            )}
            <Spacer sm />
            <Paganate
                nextPage={nextPage}
                prevPage={prevPage}
                onChange={onChange}
                searchTerm={ourRecommendationsLabel}
                pagination={pagination}
            />
        </>
    );
};

export default FeaturedProducts;
