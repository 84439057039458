import { ReactNode } from "react";

import {
    CurationsContainerGrid,
    CurationsContainerGridSkeleton,
    CurationsContainerSlider,
    CurationsContainerSliderSkeleton,
} from "@holibob-packages/ui-core/curation";

import { useCurationsListViewMode } from "../hooks/useCurationsListViewMode";

export function CurationsContainer({ children }: { children: ReactNode }) {
    const displayMode = useCurationsListViewMode();
    if (displayMode === "slider") {
        return <CurationsContainerSlider>{children}</CurationsContainerSlider>;
    }

    return <CurationsContainerGrid>{children}</CurationsContainerGrid>;
}

export function CurationsContainerSkeleton() {
    const displayMode = useCurationsListViewMode();
    if (displayMode === "slider") {
        return <CurationsContainerSliderSkeleton />;
    }
    return <CurationsContainerGridSkeleton />;
}
