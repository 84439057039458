export class DestinationSearchEvent extends Event {
    static NAME = "destination:search";

    constructor(readonly phrase: string) {
        super(DestinationSearchEvent.NAME, {
            bubbles: true,
            cancelable: false,
        });
    }
}
